import api from "@/api/axiosFactory";
import CONFIG from "@/config";

export default {
    async listSubscriptions(context, {page, query, active}) {
        const list = await api({
            url: CONFIG.api.admin.subscriptions,
            params: {
                page,
                query,
                active,
            },
        });
        return list.data;
    },
    async editSubscription(context, {id, data}) {
        const edit = await api({
            url: `${CONFIG.api.admin.subscriptions}/${id}`,
            method: "post",
            data,
        });
        return edit.data;
    },
    async listUsers(context, {page, query}) {
        const list = await api({
            url: CONFIG.api.admin.users,
            params: {
                page,
                query,
            },
        });
        return list.data;
    },
    async editUser(context, {data}) {
        const edit = await api({
            url: `${CONFIG.api.admin.users}`,
            method: "post",
            data,
        });
        return edit.data;
    },
    async editUserRoles(context, {id, data}) {
        const edit = await api({
            url: `${CONFIG.api.admin.users}/${id}/roles`,
            method: "post",
            data,
        });
        return edit.data;
    },
    async editUserEmail(context, {id, data}) {
        const edit = await api({
            url: `${CONFIG.api.admin.users}/${id}/email`,
            method: "post",
            data,
        });
        return edit.data;
    },
    async editCompanyType(context, {id, data}) {
        const edit = await api({
            url: `${CONFIG.api.admin.users}/${id}/company-type`,
            method: "post",
            data,
        });
        return edit.data;
    },
    async showUser(context, {id, data}) {
        const edit = await api({
            url: `${CONFIG.api.admin.users}/${id}/show`,
            method: "post",
            data,
        });
        return edit.data;
    },
    async getInvoices(context, {id}) {
        const invoices = await api({
            url: `${CONFIG.api.admin.users}/${id}/invoices`,
            method: "get",
        });
        return invoices.data;
    },
    async getDocuments(context, {id}) {
        const invoices = await api({
            url: `${CONFIG.api.admin.users}/${id}/documents`,
            method: "get",
        });
        return invoices.data;
    },
    async saveDocuments(context, {id, data}) {
        const invoices = await api({
            url: `${CONFIG.api.admin.users}/${id}/documents`,
            method: "post",
            data,
        });
        return invoices.data;
    },
    async saveManyDocuments(context, {data}) {
        const documents = await api({
            url: CONFIG.api.admin.documents,
            method: "post",
            data,
        });
        return documents.data;
    },
    async removeDocument(context, {id, fileId}) {
        const invoices = await api({
            url: `${CONFIG.api.admin.users}/${id}/documents/${fileId}`,
            method: "delete",
        });
        return invoices.data;
    },
    async getToken(context, {id}) {
        const token = await api({
            url: `${CONFIG.api.admin.users}/${id}/token`,
            method: "get",
        });
        return token.data;
    },
    async sendPin(context, {id, facilityCode}) {
        const sms = await api({
            url: `${CONFIG.api.admin.users}/${id}/text/${facilityCode}`,
            method: "post",
        });
        return sms.data;
    },
    async listPages() {
        const list = await api({
            url: CONFIG.api.admin.pages,
        });
        return list.data;
    },
    async editPage(context, {id, data}) {
        const edit = await api({
            url: `${CONFIG.api.admin.pages}/${id}`,
            method: "post",
            data,
        });
        return edit.data;
    },
};
